import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import Button from "../../components/shared/button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ContactAgentTwoSection = ({ data }) => {
    return (
        <section className="contact-agent-section section-space-ptb">
            <div className="container-full">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title.heading}
                        {...data.section_title}
                    />
                )}
                <div className="content-block max-w-5xl mx-auto">
                    <div className="agent-contact-form ajax-form">
                        <form action="#">
                            <div className="grid grid-cols-2 gap-x-10 gap-y-5">
                                <div className="single-input">
                                    <input
                                        className="form-control w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                        type="text"
                                        name="name"
                                        placeholder="Your Name"
                                    />
                                </div>
                                <div className="single-input">
                                    <input
                                        className="form-control w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                        type="text"
                                        name="phone"
                                        placeholder="Your Phone"
                                    />
                                </div>
                                <div className="single-input col-span-2">
                                    <input
                                        className="form-control w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                        type="email"
                                        name="email"
                                        placeholder="Your Email Address *"
                                    />
                                </div>
                                <div className="single-input col-span-2">
                                    <textarea
                                        className="form-control h-44 w-full bg-transparent border-b border-[#CFCFD4] py-4 focus:outline-none"
                                        name="message"
                                        placeholder="How can we help?"
                                    ></textarea>
                                </div>
                                <div className="mt-4 text-center col-span-2">
                                    <Button
                                        color="transparent"
                                        type="submit"
                                        outline="dark"
                                        className="px-12"
                                    >
                                        Send Message
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {data?.agent &&
                        data?.agent.map((agent) => (
                            <div
                                key={agent.id}
                                className="agent-item section-space-pt mx-auto text-center mb-10 lg:mb-0"
                            >
                                {agent?.image && (
                                    <div className="agent-image mx-auto w-32 h-32 mb-6">
                                        <GatsbyImage
                                            className="rounded-full "
                                            image={getImage(agent?.image?.src)}
                                            alt={
                                                agent?.image?.alt ||
                                                "Agent Image"
                                            }
                                        />
                                    </div>
                                )}
                                <div className="agent-details">
                                    {agent.name && (
                                        <h3 className="agent-name mb-0">
                                            {agent.name}
                                        </h3>
                                    )}
                                    {agent.designation && (
                                        <span className="text-[13px] mb-6 block">
                                            {agent.designation}
                                        </span>
                                    )}
                                    {agent.email && (
                                        <a
                                            className="text-[#515151] hover:text-primary"
                                            href={`mailto:${agent.email}`}
                                        >
                                            {agent.email}
                                        </a>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
};

ContactAgentTwoSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        agent: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default ContactAgentTwoSection;
