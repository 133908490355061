import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import SectionTitle from "../../components/section-title";

const EcopiGarder = ({ data }) => {
    return (
        <section className="ecopi-garder-section relative">
            <div className="section-space-ptb bg-[#221F1E]">
                <div className="container-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        <div className="about-image relative mr-0 xl:mr-[35px] pr-0 lg:pr-[70px] xl:pr-[190px] pb-[50px] xl:pb-[130px]">
                            <GatsbyImage
                                className="signature-image w-[85%] md:w-auto"
                                image={getImage(data?.images[0].src)}
                                alt=""
                            />
                            <div className="inner-image absolute right-0 bottom-0 w-[85%]">
                                <GatsbyImage
                                    className="signature-image absolute right-0 bottom-0"
                                    image={getImage(data?.images[1].src)}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="content lg:ml-4">
                            {data?.section_title && (
                                <SectionTitle
                                    heading={data?.section_title?.heading}
                                    {...data.section_title}
                                />
                            )}
                            {data?.texts.map((item) => (
                                <p
                                    className="mb-5 text-white"
                                    key={`${item.id}-item`}
                                >
                                    {item.content}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <span className="about-image-down -bottom-[2px] w-full h-[133px]">
                <StaticImage
                    className=""
                    src="../../data/images/about-us/about.png"
                    alt=""
                />
            </span>
        </section>
    );
};

EcopiGarder.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            })
        ),
    }),
};

export default EcopiGarder;
