/* eslint-disable max-len */
import React, { Children } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";

const FloorPlanTabTwoArea = ({ children }) => {
    const childArr = Children.toArray(children);
    return (
        <section className="floor-plan-section section-space-ptb bg-[#F5F1EF]">
            <div className="container-full">
                <Tabs>
                    <TabList className="floor-plan-nav-two flex flex-wrap mb-14">
                        {childArr?.map((item) => (
                            <Tab
                                key={item.key}
                                className="grow basis-0 relative"
                            >
                                {item?.props?.data?.section_title?.heading && (
                                    <button
                                        type="button"
                                        className="cursor-pointer whitespace-nowrap before:w-full before:absolute before:border-b-[3px] before:border-[#D0CDCB] before:z-[1] before:bottom-[-3px] before:left-0 before:h-[3px] before:content-['']  text-black text-[18px] md:text-[22px] lg:text-[24px] font-bold relative flex justify-center w-full p-[15px_15px] lg:p-[30px_15px]"
                                        dangerouslySetInnerHTML={{
                                            __html: item.props.data
                                                .section_title.heading,
                                        }}
                                    />
                                )}
                            </Tab>
                        ))}
                    </TabList>
                    <div className="tab-content">
                        {childArr?.map((item) => (
                            <TabPanel key={item.key}>{item}</TabPanel>
                        ))}
                    </div>
                </Tabs>
            </div>
        </section>
    );
};
FloorPlanTabTwoArea.propTypes = {
    children: PropTypes.node.isRequired,
};
export default FloorPlanTabTwoArea;
