import React from "react";
import PropTypes from "prop-types";
import FacilitieCard from "../../components/facilitie-card";
import SectionTitle from "../../components/section-title";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FacilitiesSection = ({ data }) => {
    return (
        <section className="facilities-section section-space-pt">
            <div className="container-full">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title?.heading}
                        {...data.section_title}
                    />
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8">
                    {data?.items.map((item) => (
                        <FacilitieCard
                            key={item.id}
                            icon={item.icon}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </div>
            </div>
            <div className="banner-images-wrap mt-[100px]">
                {data?.images && (
                    <GatsbyImage
                        className="w-full"
                        image={getImage(data?.images[0].src)}
                        alt={data?.images[0].alt}
                    />
                )}
            </div>
        </section>
    );
};

FacilitiesSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default FacilitiesSection;
