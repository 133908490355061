import React from "react";
import PropTypes from "prop-types";
import Socials from "../../../components/widgets/socials";

const FooterTwo = ({ data }) => {
    const style = {
        social: `relative hover:bg-primary hover:text-white bg-[#F3F3F9] rounded-full flex justify-center items-center w-[45px] h-[45px] text-[#515151]`,
    };
    return (
        <footer className="footer-section">
            <div className="footer-bottom">
                <div className="container-full">
                    <div className="border-t py-12 grid grid-cols-1 sm:grid-cols-3 items-center">
                        <div className="copyritht text-center sm:text-left mt-5 sm:mt-0 order-last sm:order-1">
                            &copy; {new Date().getFullYear()}
                            <i className="icofont-heart"></i> BY{" "}
                            <a
                                className="hover:text-primary"
                                href="https://hasthemes.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Ecostate Park.
                            </a>{" "}
                            All Rights Reserved
                        </div>
                        <ul className="flex space-x-3 justify-center sm:order-2">
                            {data?.footer[0]?.socials?.map((item) => (
                                <li key={item.id}>
                                    <a
                                        href={item.link}
                                        className={`${style.social} `}
                                    >
                                        <i className={item.icon}></i>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <ul className="footer-language sm:order-3 flex mt-5 sm:mt-0 justify-center sm:justify-end space-x-6 justify-content-center">
                            <li>
                                <a href="/">Eng</a>
                            </li>
                            <li>
                                <a href="/">Rus</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};
FooterTwo.propTypes = {
    data: PropTypes.shape({
        footer: PropTypes.arrayOf(
            PropTypes.shape({
                contact_info: PropTypes.array,
                list: PropTypes.array,
                socials: PropTypes.array,
            })
        ),
    }),
};
export default FooterTwo;
