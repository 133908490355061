import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import HeroSection from "../container/hero-area/layout-02";
import Header from "../layouts/header/layout-02";
import FooterTwo from "../layouts/footer/layout-02";
import FunFactSection from "../container/funfact-area/layout-02";
import EcopiGarder from "../container/ecopi-garden-section";
import FacilitiesSection from "../container/facilities-area";
import FloorPlanTabTwoArea from "../container/floor-plan/floor-nav-two";
import TabContentOne from "../container/floor-plan/floor-content/layout-02";
import TestimonialSectionTwo from "../container/testimonial/layout-02";
import MapLocationListTwoSection from "../container/map-location-list-area-two";
import ContactAgentTwoSection from "../container/contact-agent-two";
import GallerySection from "../container/gallery-h-area";

const HomeTwoPage = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);

    return (
        <Layout>
            <SEO title="Home Two page" pathname="/" />
            <Header data={globalContent?.["menu"]} />
            <HeroSection data={content?.["hero-section"]} />
            <FunFactSection data={content?.["funfact-section"]} />
            <EcopiGarder data={content?.["ecopi-garder-section"]} />
            <FacilitiesSection data={content?.["ecopis-facilities-section"]} />
            <FloorPlanTabTwoArea>
                <TabContentOne data={content?.["basement-floor"]} />
                <TabContentOne data={content?.["ground-floor"]} />
                <TabContentOne data={content?.["2-nd-floor"]} />
                <TabContentOne data={content?.["rooftop-floor"]} />
            </FloorPlanTabTwoArea>
            <GallerySection
                data={{
                    ...content?.["gallery-section"],
                    items: data?.allGallery?.nodes,
                }}
            />
            <TestimonialSectionTwo data={content?.["testimonial-section"]} />
            <MapLocationListTwoSection
                data={content?.["map-location-list-section"]}
            />
            <ContactAgentTwoSection
                data={{
                    ...content?.["contact-agent-section"],
                    agent: data?.allAgentTeam?.nodes,
                }}
            />
            <FooterTwo data={globalContent?.["footer"]} />
        </Layout>
    );
};

HomeTwoPage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allAgentTeam: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGallery: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query HomeTwoPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "home-two" }, pageType: { eq: homepage }) {
            content {
                ...PageContentAll
            }
        }
        latestPosts: allArticle(
            limit: 3
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
            }
        }
        allAgentTeam {
            nodes {
                id
                name
                slug
                phone
                email
                designation
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(quality: 100)
                        }
                    }
                }
            }
        }
        allGallery {
            nodes {
                id
                title
                category {
                    slug
                    title
                }
                video
                thumb {
                    src {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                height: 600
                                width: 300
                                formats: WEBP
                                placeholder: DOMINANT_COLOR
                                quality: 100
                            )
                        }
                    }
                    alt
                }
                image {
                    src {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                formats: WEBP
                                placeholder: DOMINANT_COLOR
                                quality: 100
                            )
                        }
                    }
                    alt
                }
            }
        }
    }
`;
export default HomeTwoPage;
