import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../../components/section-title";
import TestimonialCardTwo from "../../../components/testimonial/layout-02";
import SwiperSlider, { SwiperSlide } from "@components/shared/swiper";

const TestimonialSectionTwo = ({ data }) => {
    return (
        <section className="testimonial-section section-space-pt section-space-pb-90">
            <div className="container-full">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title?.heading}
                        {...data.section_title}
                    />
                )}
                <SwiperSlider
                    className="testimonial-slider center relative group"
                    options={{
                        autoplay: false,
                        slidesPerView: 1,
                        navigation: false,
                        pagination: true,
                        spaceBetween: 30,
                        loop: true,
                        centeredSlides: false,
                        breakpoints: {
                            420: {
                                slidesPerView: 1,
                            },
                            672: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 50,
                            },
                            1400: {
                                slidesPerView: 2,
                                spaceBetween: 120,
                            },
                        },
                    }}
                    navPosition={2}
                >
                    {data?.items?.map((item) => (
                        <SwiperSlide key={item.id}>
                            <TestimonialCardTwo
                                description={item?.description}
                                images={item?.images}
                                name={item?.name}
                                designation={item?.designation}
                            />
                        </SwiperSlide>
                    ))}
                </SwiperSlider>
            </div>
        </section>
    );
};

TestimonialSectionTwo.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default TestimonialSectionTwo;
