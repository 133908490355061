import React from "react";
import PropTypes from "prop-types";
import FunFact from "../../../components/funfact/layout-02";

const FunFactSection = ({ data }) => {
    return (
        <section className="funfact-section bg-[#221F1E]">
            <div className="container-full">
                <div className="right-content section-space-pb border-b border-[#d9d9d9]">
                    <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-5 gap-x-8 md:gap-x-20">
                        {data?.items &&
                            data?.items.map((item) => (
                                <FunFact
                                    key={item.id}
                                    countNumber={item.countNumber}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

FunFactSection.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default FunFactSection;
