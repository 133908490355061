import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/shared/button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TabContentOne = ({ data }) => {
    return (
        <div className="grid gap-10 grid-cols-1 lg:grid-cols-12">
            <div className="floor-plan-content lg:pb-[100px] lg:col-span-5 xl:col-span-4">
                {data?.headings && (
                    <h2 className="floor-plan-title mb-7 text-[48px]">
                        {data?.headings[0]?.content}
                    </h2>
                )}
                {data?.texts && (
                    <div className="floor-plan-text mb-7">
                        <p>{data?.texts[0]?.content}</p>
                    </div>
                )}
                {data?.items && (
                    <ul className="floor-plan-info">
                        {data?.items.map((floorItem) => (
                            <li
                                key={floorItem?.id}
                                className="border-[#CFCFD4] text-black border-b last:border-none text-lg p-[10px_0] flex justify-between"
                            >
                                {floorItem?.title}
                                <span>{floorItem?.description}</span>
                            </li>
                        ))}
                    </ul>
                )}
                {data?.buttons && (
                    <div className="button-group mt-12 space-x-4">
                        {data?.buttons.map(({ id, content, ...props }) => (
                            <Button key={id} {...props}>
                                {content}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
            {data?.images && (
                <div className="floor-plan-image text-center lg:col-span-7 xl:col-span-8">
                    <GatsbyImage
                        className=""
                        image={getImage(data?.images[0].src)}
                        alt={data?.images[0].alt}
                    />
                </div>
            )}
        </div>
    );
};

TabContentOne.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default TabContentOne;
