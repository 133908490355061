import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { LightgalleryItem } from "react-lightgallery";

const GalleryCard = ({ title, image, video, thumb }) => {
    return (
        <LightgalleryItem
            group="any"
            src={image || video}
            thumb={thumb?.publicURL}
        >
            <div className="gallery-card relative overflow-hidden block group cursor-pointer">
                <span
                    className="group-hover:opacity-100 opacity-0 before:transition-all h-full 
                w-full before:z-[2] before:h-full before:w-full before:bg-primary/50 before:absolute before:left-0 before:top-0 before:content-['']"
                />
                {image ? (
                    <div className="image-wrapper">
                        <GatsbyImage
                            className="group-hover:scale-105 transition-all w-full"
                            image={getImage(thumb)}
                            alt={title || "Gallery"}
                        />
                    </div>
                ) : (
                    <div className="video-wrapper relative">
                        <GatsbyImage
                            className="group-hover:scale-105 transition-all w-full"
                            image={getImage(thumb)}
                            alt={title || "Gallery"}
                        />
                        <div className="video-button absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                            <button
                                type="button"
                                aria-label="button"
                                className="text-3xl absolute z-10 text-white bg-black/50 top-[47%] 
                                left-[47%] h-24 w-24 leading-[80px] 
                                -translate-x-1/2 -translate-y-1/2 rounded-full"
                            >
                                <i className="fas fa-play"></i>
                            </button>
                            <div className="video-animation absolute w-56 h-56 -top-[110px] -left-[6.9rem]">
                                <div className="animation animate-waves animate-[waves_1.5s_ease-in-out_infinite]" />
                                <div className="animation animate-waves animate-[waves_2.5s_ease-in-out_infinite]" />
                                <div className="animation animate-waves animate-[waves_3.5s_ease-in-out_infinite]" />
                            </div>
                        </div>
                    </div>
                )}
                <h6 className="text-[16px] absolute z-10 bottom-0 left-0 pl-5 text-white group-hover:pb-5 group-hover:opacity-100 transition-all opacity-0">
                    {title}
                </h6>
            </div>
        </LightgalleryItem>
    );
};

GalleryCard.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    thumb: PropTypes.object,
    group: PropTypes.string,
    video: PropTypes.string,
};

export default GalleryCard;
