import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "../../../components/shared/button";
const HeroSection = ({ data }) => {
    return (
        <section className="hero-section relative">
            <div className="relative z-[1] flex items-center h-auto p-[100px_0] lg:p-[140px_0] xl:p-[240px_0] 2xl:p-[270px_0]">
                <span className="absolute -z-[1] top-0 right-0 left-0 w-full h-full bg-gradient-to-tr from-[#221f1e]"></span>

                <div className="container-full">
                    <div className="hero-content mt-10 grid grid-cols-1 gap-10 md:grid-cols-2">
                        <div className="left-side-hero-content">
                            {data?.headings[1] && (
                                <h1
                                    className="hero-title font-playfair font-normal text-white sm:-ml-2 lg:-ml-3 text-[50px] sm:text-[70px] sm:leading-[70px] md:text-[70px] md:leading-[80px] lg:text-[90px] lg:leading-[110px] xl:text-[180px] xl:leading-[160px] uppercase"
                                    dangerouslySetInnerHTML={{
                                        __html: data?.headings[1].content,
                                    }}
                                />
                            )}
                            <GatsbyImage
                                className="signature-image w-52 lg:w-auto"
                                image={getImage(data?.images[1].src)}
                                alt=""
                            />
                            {data?.headings[0] && (
                                <div className="block">
                                    <h4 className="hero-sub-title text-lg lg:text-2xl  mt-2 font-normal px-5 lg:px-7 py-4 bg-black inline-block text-white">
                                        <i className="fi fi-maps-and-flags mr-5"></i>
                                        {data?.headings[0].content}
                                    </h4>
                                </div>
                            )}
                        </div>
                        <div className="flex lg:justify-end">
                            <div className="pricing relative block w-full max-w-[490px] p-[30px] lg:p-[70px]">
                                <div className="images absolute top-0 right-0 bottom-0 left-0">
                                    <GatsbyImage
                                        className="absolute w-full h-full"
                                        image={getImage(data?.images[2].src)}
                                        alt=""
                                    />
                                </div>
                                <h6 className="pricing-title text-base block mb-7 lg:mb-[48px] relative uppercase text-white">
                                    Initial Price
                                </h6>
                                <h2 className="pricing-amount font-playfair text-5xl sm:text-6xl lg:text-[84px] font-normal text-[#FFCA3B] leading-[1.071] relative block mb-7">
                                    $425,500
                                </h2>
                                <h3 className="pricing-tax text-[30px] font-normal leading-[1.071] relative block mb-8 lg:mb-[53px] text-white">
                                    Included Tax
                                </h3>
                                {data?.buttons && (
                                    <div className="button-group mt-4 mb-8 lg:mb-12 space-x-4 hero-btn">
                                        {data?.buttons.map(
                                            ({ id, content, ...props }) => (
                                                <Button
                                                    key={id}
                                                    {...props}
                                                    className="w-full"
                                                >
                                                    {content}
                                                </Button>
                                            )
                                        )}
                                    </div>
                                )}
                                <div className="pricing-support-box relative block pl-[66px]">
                                    <span className="text-xs absolute top-0 left-0 uppercase leading-3 text-white">
                                        call
                                        <br />
                                        direct{" "}
                                    </span>
                                    <a
                                        href="tel:+5435682625"
                                        className="text-primary text-[24px] font-normal leading-6"
                                    >
                                        +5435682625
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-bg absolute -z-[2] top-0 right-0 bottom-0 left-0 transition-[all_6s_linear]">
                        <GatsbyImage
                            className="w-full h-full cover object-cover"
                            image={getImage(data?.images[0].src)}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
//
HeroSection.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            })
        ),
    }),
};

export default HeroSection;
